body {
  margin: 0;
  padding: 0;
  font-family: "Space Mono", Arial, serif;
  color: aliceblue;
}

html {
  scroll-behavior: smooth;
}

#yadab-aboutMe {
  scroll-margin-top: 120px;
}

#yadab-skills {
  scroll-margin-top: 40px;
}

#yadab-resume {
  scroll-margin-top: 20px;
}
