@import '../../constants/scss/constants.scss';

.container {
    background: linear-gradient(90deg, rgba(59,117,129,1) 0%, rgba(65,65,139,1) 71%, rgba(59,117,129,1) 100%);
    box-shadow: 0 0 5px 5px #888;
    color: aliceblue;
    padding-bottom: 3rem;
    padding-top: 1rem;
    -moz-box-shadow: 0 0 5px 5px #888;
    -webkit-box-shadow: 0 0 5px 5px#888;
}

.content {
    padding: 1rem 0;
}

.inverseContainer {
    background: linear-gradient(73deg, rgba(159,173,195,1) 5%, rgba(165,181,203,1) 51%, rgba(137,161,196,1) 95%);
    color: black;
}

.inverseTitle {
    border-bottom: 1px solid black !important;
}

.sectionContent {
    margin: 0 auto;
    max-width: map-get($grid-breakpoints, xl);
    padding: 0 1rem;
}

.title {
    border-bottom: 1px solid aliceblue;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
