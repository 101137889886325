.aboutMeText {
  color: black;
  font-size: 1.5em;
}

.container {
  background: linear-gradient(73deg, rgba(159,173,195,1) 5%, rgba(165,181,203,1) 51%, rgba(137,161,196,1) 95%);
}

.header {
  color: black;
  font-size: 45px;
  font-weight: 700;
}

.profile {
  font-size: 1.5rem;
}

.profileItem {
  display: list-item;
  list-style-type: circle;
}

.socials {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
