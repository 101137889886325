#yadab-contact{
    background: rgb(54,173,198);
    background: linear-gradient(90deg, rgba(59,117,129,1) 0%, rgba(65,65,139,1) 71%, rgba(59,117,129,1) 100%);
    box-shadow: 0 0 5px 5px #888;
    color:aliceblue;
    font-size: 1.5rem;
    padding: 2% 0 1% 0;
}

#yadab-contact textarea,
#yadab-contact input {
    background: none;
    border: 2px dashed rgba(255, 255, 255, 0);
    border-radius: 0;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
    cursor: text;
    text-align: center;
    font-family: 'Lato', georgia;
    font-size: 25px;
    font-weight: 700;
    outline: none;
    padding: 1rem;
}

.contact-input {
    width: 10rem;
}

#yadab-contact textarea {
    border-color: rgba(255, 255, 255, 0);
    min-height: 10vh;
    resize: none;
    text-align: left;
    width: 100%;
}

#yadab-contact textarea:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px dashed rgba(255, 255, 255, 1);
}

#yadab-contact textarea:focus:required:valid {
    border: 2px solid rgba(255, 255, 255, 0);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

#yadab-contact textarea:required:valid {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

#yadab-contact input {
    border-bottom: 2px dashed rgba(255, 255, 255, 0.5);
}

#yadab-contact input:required,
#yadab-contact textarea:required {
    border-bottom: 2px dashed rgba(255, 255, 255, 0.5);
}

#yadab-contact input:focus {
    border-bottom: 2px dashed rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.2);
}

#yadab-contact input:required:valid {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

#yadab-contact input:required:invalid {
    color: rgba(255, 255, 255, 0.5);
}

#yadab-contact ::-webkit-input-placeholder {
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    font-style: italic;
    font-weight: 400;
}

.message-heading {
    margin-top: 5%;
}

.rightToCenterText {
    text-align: right;
}

.leftToCenterText {
    text-align: left;
}

.send-button button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: aliceblue;
}

.send-button button:hover {
    color: black;
}

.send-button button:active {
    background-color: transparent;
}

textarea { resize: both; }
textarea.vert { resize: vertical; }
textarea.noResize { resize: none; }

@media (max-width: 992px) {
  .rightToCenterText { text-align: center; }

  .leftToCenterText { text-align: center; }
  
  .inputBox { max-width: 30rem; }
}