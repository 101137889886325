.active-flag {
    opacity: 1 !important;
    transition: all .5s;
}

.navbar-fixed {
    transition: all 1s;
}

nav {
    background-color: transparent;
    width: 100vw;
}
  
nav ul li a {
    font-size: 20px;
    font-family: "Space Mono", Arial, serif;
    padding: 0 1rem;
}

.fixed-me{
    background-color:#023f5c !important;
    padding: 0px  !important;
    position: fixed;
    top:0;
    transition: all 1s ;
    z-index:888;
    width: 100vw;
}

.fixed-me ul li:hover, .fixed-me ul li:active {
    background-color: teal;
}

#lang-switch button {
    opacity: 0.5;
}

#lang-switch img {
    height: 32px;
    margin: 0 1vh 0 0;
    transition: all .5s;
    width: 32px;
}

#lang-switch button:hover {
    cursor: pointer;
    opacity: 1;
}

#lang-switch button{
    background-color: transparent;
    border: none;
}

.yadabGreeting {
    font-size: 2rem;
}
