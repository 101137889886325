.container {
    height: 100vh;
}

.description {
    font-size: 1.5rem;
}

.particle {
    align-items: center;
    position: absolute;
    top: 0;
    background-image: linear-gradient(to left, black 10%, transparent 60%),
                    url('../../resources/images/cover.jpg');
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    height: 100vh;
    width: 100%;
}

.scrollDown {
    bottom: 5rem;
    left: 50%;
    position: absolute;
    z-index: 3;
}

.scrollDownIcon {
    color: aliceblue;
    font-size: 4rem !important;
}

.title {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    max-width: 700px;
    position: absolute;
    width: 100%;

    h1 {
      margin: 0;
      width: 100%;
    }
}

@media (max-width: 870px) {
  .particle {
    background-image: url("../../resources/images/mobile_cover.jpg");
  }
}

@media (max-width: 420px) {
  .title {
    left: 5%;
    right: unset;
    top: 15%;
    h1 {
      font-size: 2.5rem;
    }
  }
}
