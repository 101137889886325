.skills {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(auto , 182px));
}

.typeScript {
    font-size: 9.6em !important;

    &:hover {
        color: #007acc;
    }
}
