@import '../../constants/scss/constants.scss';

.contact {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
    max-width: map-get($grid-breakpoints, xl);
}

.section {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 42px;
}

.yadabFooter {
    background: linear-gradient(73deg, rgba(159,173,195,1) 5%, rgba(165,181,203,1) 51%, rgba(137,161,196,1) 95%);
    box-shadow: 0 0 5px 5px #888;
    color: black;
    padding: 2rem 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -moz-box-shadow: 0 0 5px 5px #888;
    -webkit-box-shadow: 0 0 5px 5px#888;
    font-size: 1.25rem;
}

.yadabLink {
    color: black;
}
