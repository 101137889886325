
.social-icons {
    background-color: transparent;
  }
  
.facebook-color:hover {
  background-color:#3B5998;
  vertical-align: 1;
}

.twitter-color:hover {
  background-color:#33ccff;
}

.linkedin-color:hover {
  background-color:#007bb7;
}

.github-color:hover {
  background-color:#000;
}

.instagram-color:hover {
  background-color:#3f729b;
}

.mail-color:hover {
  background-color: teal;
}

@media (max-width: 870px) {
  .fixed-action-btn {
    bottom: 3rem;
    right: 2rem;
  }
}
