.timeline {
  list-style: none;
  overflow: hidden;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #e6e6e6;
  left: 50%;
  margin-left: 0px;
}

.timeline .timeline-heading > div h3 {
  background: #e6e6e6;
  border-radius: 4px;
  color: black;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 45%;
  float: left;
  margin-bottom: 0;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 3rem;
  height: 3rem;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -21px;
  background-color: #023f5c;
  z-index: 100;
  display: table;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.timeline > li > .timeline-badge i {
  display: table-cell;
  font-size: 18px;
  height: 44px;
  vertical-align: middle;
}
.timeline > li.timeline-unverted {
  text-align: right;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
}

.company {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  color: #bfbfbf;
}


@media screen and (max-width: 700px) {
  .timeline .timeline-heading {
    text-align: left;
  }

  .timeline > li.timeline-unverted {
    text-align: left;
  }

  ul.timeline:before {
    left: 8vw;
  }

  ul.timeline > li > .timeline-panel {
    width: 70vw
  }

  ul.timeline > li > .timeline-badge {
    left: 8vw;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    right: auto;
  }
}
